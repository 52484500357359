<template>
  <div class="top-tutors-card">
    <div class="flex items-center space-x-6">
      <div class="w-2/5">
        <TeacherListItemsAvatar mode="card" :show-btn="false" />
      </div>
      <div class="w-3/5 overflow-hidden">
        <TeacherListItemsName classes="lg:text__body-1-md--bold text__caption-xs--bold" />
        <TeacherListItemsTeachingCourse class=" mt-2 lg:mt-4" />
        <TeacherListItemsReview class="mt-1" just-overall />
        <TeacherListItemsLessons class="mt-1" />
      </div>
    </div>
    <div>
      <TeacherListItemsHighlights
        class="mt-4 text-left h-[140px]"
        :max-show="2"
      />
    </div>
    <div class="mt-8 text-center">
      <BaseSeparator />
      <BaseButton
        class="mt-4 mx-auto"
        flat
        :label="$t('common.actions.see_profile')"
        light
        size="sm"
        :to="teacher.teacher_page"
        tag="a"
      />
    </div>
  </div>
</template>
<script>
import TeacherListItemsAvatar from '@/components/common/teacher-list-items/teacher-list-items-avatar.vue'
import TeacherListItemsName from '@/components/common/teacher-list-items/teacher-list-items-name.vue'
import { _teacher } from '@/components/common/teacher-list-items/constructor'
import TeacherListItemsTeachingCourse
from '@/components/common/teacher-list-items/teacher-list-items-teaching-course.vue'
import TeacherListItemsReview from '@/components/common/teacher-list-items/teacher-list-items-review.vue'
import TeacherListItemsLessons from '@/components/common/teacher-list-items/teacher-list-items-lessons.vue'
import TeacherListItemsHighlights from '@/components/common/teacher-list-items/teacher-list-items-highlights.vue'

export default {
  name: 'TopTutorsCard',
  components: {
    TeacherListItemsHighlights,
    TeacherListItemsLessons,
    TeacherListItemsReview,
    TeacherListItemsTeachingCourse,
    TeacherListItemsName,
    TeacherListItemsAvatar
  },
  provide () {
    return { teacher: _teacher(this.teacher) }
  },
  props: {
    teacher: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped lang="scss">
.top-tutors-card {
  @apply border border-primary-100 p-4 lg:p-8 rounded-lg w-full pb-2;
}
</style>
